.countdown_timer {
    text-align: center;
    font-size: 2em;
}

.cdtp_navlink_home{
    float: right;
}

#navbar > * {
    padding: 10px;
}